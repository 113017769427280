import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// import required modules
import { Pagination, Autoplay, Navigation } from 'swiper'
import { useViewport } from '@/hooks/useViewport'
import Image from '@/components/Image'
import { useTranslation } from 'react-i18next'

const Testimonial = () => {
  const { isDesktop, isWidescreen, isMobile } = useViewport()
  const { t } = useTranslation()
  return (
    <section className="section section-lg text-center t-section">
      <div className="container">
        <h3 className="wow-outer av-title">
          <span className="wow slideInDown" style={{ marginBottom: '3rem' }}>
            {t('trustUs')}
          </span>
        </h3>
        <Swiper
          pagination={{
            type: 'fraction',
          }}
          slidesPerView={(isWidescreen && 3) || (isDesktop && 2) || 1}
          loop={true}
          autoplay={{
            delay: 2500,
          }}
          navigation={false}
          modules={[Pagination, Navigation, Autoplay]}
        >
          <SwiperSlide>
            <blockquote className="quote-classic">
              <div>
                <img src="/images/quote.svg" alt="Quotation marks" />
              </div>
              <div className="quote-classic-meta">
                <div className="quote-classic-avatar">
                  <Image name="testimonials-person-3-96x96.jpg" alt="img" />
                </div>
                <div className="quote-classic-info">
                  <cite className="quote-classic-cite fw-bold">Albert Williams</cite>
                  <p className="quote-classic-caption">CEO at Majestic</p>
                </div>
              </div>
              <div className="quote-classic-text">
                <p>
                  They have highly qualified staff, efficient and dedicated to their work. They
                  handle our monthly bookkeeping and prepare monthly reports so I can more clearly
                  manage my business.
                </p>
              </div>
            </blockquote>
          </SwiperSlide>
          <SwiperSlide>
            <blockquote className="quote-classic">
              <div>
                <img src="/images/quote.svg" alt="Quotation marks" />
              </div>
              <div className="quote-classic-meta">
                <div className="quote-classic-avatar">
                  <Image name="testimonials-person-1-96x96.jpg" alt="img" />
                </div>
                <div className="quote-classic-info">
                  <cite className="quote-classic-cite fw-bold">Kelly Spencer</cite>
                  <p className="quote-classic-caption">Private Entrepreneur</p>
                </div>
              </div>
              <div className="quote-classic-text">
                <p>
                  As a result of their excellent work, my business has been able to increase
                  profitability by simplifying employee payments, tax filing, and more with the
                  right payroll service.
                </p>
              </div>
            </blockquote>
          </SwiperSlide>
          <SwiperSlide>
            <blockquote className="quote-classic">
              <div>
                <img src="/images/quote.svg" alt="Quotation marks" />
              </div>
              <div className="quote-classic-meta">
                <div className="quote-classic-avatar">
                  <Image name="testimonials-person-2-96x96.jpg" alt="img" />
                </div>
                <div className="quote-classic-info">
                  <cite className="quote-classic-cite fw-bold">Harold Campbell</cite>
                  <p className="quote-classic-caption">Tax Client</p>
                </div>
              </div>
              <div className="quote-classic-text">
                <p>
                  Avila's Accounting & Tax Services provides me with advice and takes the time to
                  prepare my taxes accurately. They know you well and your ever-changing tax
                  situation.
                </p>
              </div>
            </blockquote>
          </SwiperSlide>
          <SwiperSlide>
            <blockquote className="quote-classic">
              <div>
                <img src="/images/quote.svg" alt="Quotation marks" />
              </div>
              <div className="quote-classic-meta">
                <div className="quote-classic-avatar">
                  <Image name="testimonials-person-5-96x96.jpg" alt="img" />
                </div>
                <div className="quote-classic-info">
                  <cite className="quote-classic-cite fw-bold">Bill Robertson</cite>
                  <p className="quote-classic-caption">Tax Client</p>
                </div>
              </div>
              <div className="quote-classic-text">
                <p>
                  The staff at Avila's Accounting & Tax Services was very helpful in solving my
                  problems painlessly and saving me from all the stress associated with tax
                  preparation. I would recommend them.
                </p>
              </div>
            </blockquote>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}

export default Testimonial
