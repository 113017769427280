import Image from '@/components/Image'
import { Service } from '@/types'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Prop = {
  service: Service
}
const about: React.FC<Prop> = ({ service }) => {
  const { name, detail, image, benefits } = service
  const { t } = useTranslation()
  return (
    <section className="section section-lg about-service-section mb-5">
      <div className="container">
        <div className="row row-50 justify-content-center justify-content-lg-between flex-lg-row-reverse">
          <div className="col-lg-6 col-xl-5">
            <div className="inset-right-3">
              <h3>{`${t('about')} ${name}`}</h3>
              <p>{detail}</p>
            </div>
            {benefits.map((benefit, index) => (
              <div key={`benefit-${index}`} className="row content-wrapper mt-4">
                <div className="col-1 pe-0 image-wrapper">
                  <img src="/images/check-circle.svg" alt="Check Icon" />
                </div>
                <div className="col ps-2">
                  <h5>{benefit.feature}</h5>
                  <p>{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            <Image name={image} classes="swiper-slide" />
            {/* <div
              className="swiper-slide"
              style={{
                backgroundImage: `url(/images/${image})`,
                backgroundSize: 'cover',
                height: '100%',
              }}
            ></div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default about
