import React from 'react'
import Layout from '@/components/Layout'
import Footer from '@/components/Footer'
import Breadcrumbs from '@/Common/Breadcrumbs'
import Top from '@/Template/single-service/top'
import About from '@/Template/single-service/about'
import Testimonial from '@/components/Home/Testimonials'
import { graphql } from 'gatsby'
import { ServiceProps, Service } from '@/types'
import Header from '@/components/Header'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby-plugin-react-i18next'

const SingleService: React.FC<ServiceProps> = ({ data }) => {
  const { t } = useTranslation()
  const service: Service = data.allServicesJson.edges[0].node

  return (
    <Layout>
      <Header />
      <Breadcrumbs
        subTitle={t('singleService')}
        title={service.name}
        routes={[
          { to: '/', val: t('home', { ns: 'index' }) },
          { to: '/services', val: t('servicesTitle') },
          { to: '#', val: `${service.name}` },
        ]}
      />
      <Top />
      <About service={service} />
      <section className="section section-md bg-primary-darker text-center">
        <div className="container">
          <div className="box-cta-1">
            <h3>
              {t('offerQuality')} <span className="fw-bold">{t('accountingSupport')}</span>
            </h3>
            <Link
              className="button button-lg button-primary button-winona"
              to="/contact"
              placeholder=""
            >
              {t('freeConsultation')}
            </Link>
          </div>
        </div>
      </section>
      <Testimonial />
      <Footer />
    </Layout>
  )
}

export default SingleService

export const data = graphql`
  query ServicesD($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allServicesJson: allServicesJson(filter: { slug: { eq: $slug }, lang: { eq: $language } }) {
      edges {
        node {
          id
          name
          descriptionDetail
          detail
          benefits {
            feature
            description
          }
          image
        }
      }
    }
  }
`
