import React from 'react'
import { useTranslation } from 'react-i18next'

function top() {
  const { t } = useTranslation()
  return (
    <section className="section section-lg bg-gray-100">
      <div className="container">
        <div className="row row-30 px-3">
          <div className="col-sm-6 col-lg-4 wow-outer">
            <article className="box-minimal">
              <div className="box-minimal-icon fl-bigmug-line-user144 wow fadeIn"></div>
              <div className="box-minimal-main wow-outer">
                <h4 className="box-minimal-title wow slideInDown">{t('top.Employees')}</h4>
                <p className="wow fadeInUpSmall">{t('top.TeamDescription')}</p>
              </div>
            </article>
          </div>
          <div className="col-sm-6 col-lg-4 wow-outer">
            <article className="box-minimal">
              <div
                className="box-minimal-icon fl-bigmug-line-rectangular78 wow fadeIn"
                data-wow-delay=".1s"
              ></div>
              <div className="box-minimal-main wow-outer">
                <h4 className="box-minimal-title wow slideInDown" data-wow-delay=".1s">
                  {t('top.Consultations')}
                </h4>
                <p className="wow fadeInUpSmall" data-wow-delay=".1s">
                  {t('top.ConsultationDescription')}
                </p>
              </div>
            </article>
          </div>
          <div className="col-sm-6 col-lg-4 wow-outer">
            <article className="box-minimal">
              <div
                className="box-minimal-icon fl-bigmug-line-checkmark14 wow fadeIn"
                data-wow-delay=".2s"
              ></div>
              <div className="box-minimal-main wow-outer">
                <h4 className="box-minimal-title wow slideInDown" data-wow-delay=".2s">
                  {t('top.Guaranteed')}
                </h4>
                <p className="wow fadeInUpSmall" data-wow-delay=".2s">
                  {t('top.GuaranteeDescription')}
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default top
